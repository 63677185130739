<template>
  <div class="table-div">
    <!-- 表格 -->
    <el-table :data="tables" :cell-style="cellStyle" border fit height="610" @selection-change="handleSelectionChange" :empty-text="confs.emptyText" >
      <template v-for="item in filters">
        <!-- checkbox -->
        <el-table-column v-if="item.type === 'checkbox' && item.show" type="selection" fixed></el-table-column>
        <!-- 表格 -->
        <el-table-column v-else-if="item.show" :prop="item.prop" :label="item.label" :width="item.width" show-overflow-tooltip>
          <!-- 售楼部名称 -->
          <template #default="scope" v-if="item.label === '售楼部名称'">
            <el-text v-if="!scope.row.sd_name" tag="del" type="danger">售楼部已移除</el-text>
            <!-- 正常使用 -->
            <el-text v-else-if="scope.row.sd_status == 1">{{scope.row.sd_name}}</el-text>
            <!-- 软删除 -->
            <el-text v-else-if="scope.row.sd_status == 2" tag="del" type="danger">{{scope.row.sd_name}}</el-text>
          </template>
          <!-- 设备分组 -->
          <template #default="scope" v-if="item.label === '设备分组'">
            <!-- 正常使用 -->
            <el-text v-if="scope.row.gr_id != 0">{{scope.row.gr_name}}</el-text>
            <!-- 已删除 -->
            <el-text v-else-if="scope.row.gr_id == 0" tag="del" type="danger">分组已移除</el-text>
          </template>
          <!-- 集合状态 -->
          <template #default="scope" v-if="item.label === '集合状态'">
            <!-- 正常使用 -->
            <el-text v-if="scope.row.face_status === 1" type="success">{{scope.row.face_status_msg}}</el-text>
            <!-- 集合已满 -->
            <el-text v-else-if="scope.row.face_status === 2" type="warning">{{scope.row.face_status_msg}}</el-text>
            <!-- 已注销 -->
            <el-text v-else-if="scope.row.face_status === 3" tag="del" type="danger">{{scope.row.face_status_msg}}</el-text>
          </template>
          <!-- 已注册 -->
          <template #default="scope" v-if="item.label==='已注册'">
            <span @click="ARclick(scope.row)" style="cursor: pointer; color: #189EFF">{{scope.row.face_count}}</span>
          </template>
          <!-- 主token -->
          <template #default="scope" v-if="item.label==='主FaceToken'">
            <span @click="ARclick(scope.row)" style="cursor: pointer; color: #189EFF">{{scope.row.fb_pid}}</span>
          </template>
          <!-- 图片 -->
          <template #default="scope" v-if="item.img">
            <el-image v-for="(i, index) in scope.row.img_arr" style="width: 40px; height: 40px; cursor:pointer; margin-right: 10px; background-size: cover; border-radius: 20px;" 
              :src="i" hide-on-click-modal :preview-src-list="scope.row.img_arr" :initial-index="index" :z-index="9999" :preview-teleported="true">
              <template #error>
                <el-image v-if="index === 0" :src="errorImgSrcList[0]"></el-image>
                <el-image v-if="index === 1" :src="errorImgSrcList[1]"></el-image>
              </template>
            </el-image>
          </template>
        </el-table-column>
      </template>
      <!-- 操作 -->
      <el-table-column v-if="operate" label="操作" :width="props.icons && props.icons.length >= 2 ? 100 : 60" fixed="right">
        <template #default="scope">
          <el-tooltip content="重新加载人脸资源" placement="top" v-if="props.icons?.includes('RefreshRight')">
            <el-icon @click="iconClick('RefreshRight', scope.row)" 
              color="#409EFC" style="cursor: pointer; border: 1px solid lightgray; margin-right: 8px; padding: 3px;">
              <RefreshRight />
            </el-icon>
          </el-tooltip>
          <el-tooltip content="同步设备下通道" placement="top" v-if="props.icons?.includes('HelpFilled')">
            <el-icon @click="iconClick('HelpFilled', scope.row)" 
              color="#409EFC" style="cursor: pointer; border: 1px solid lightgray; margin-right: 8px; padding: 3px;">
              <HelpFilled />
            </el-icon>
          </el-tooltip>
          <el-tooltip content="视频预览" placement="top" v-if="props.icons?.includes('VideoCameraFilled')">
            <el-icon @click="iconClick('VideoCameraFilled', scope.row)" 
              color="#409EFC" style="cursor: pointer; border: 1px solid lightgray; margin-right: 8px; padding: 3px;">
              <VideoCameraFilled />
            </el-icon>
          </el-tooltip>
          <el-tooltip content="编辑" placement="top" v-if="props.icons?.includes('Edit')">
            <el-icon @click="iconClick('Edit', scope.row)" 
              color="#409EFC" style="cursor: pointer; border: 1px solid lightgray; margin-right: 8px; padding: 3px;">
              <Edit />
            </el-icon>
          </el-tooltip>
          <el-tooltip content="注销人脸集合" placement="top" v-if="scope.row.face_status === 3 && props.icons?.includes('DeleteFilled')">
            <el-icon @click="iconClick('DeleteFilled', scope.row)"
              color="#f89898" style="cursor: pointer; border: 1px solid lightgray; margin-right: 8px; padding: 3px;">
              <DeleteFilled />
            </el-icon>
          </el-tooltip>
          <el-tooltip content="删除" placement="top" v-else-if="!scope.row.face_status && props.icons?.includes('DeleteFilled')">
            <el-icon @click="iconClick('DeleteFilled', scope.row)" 
              color="#f89898" style="cursor: pointer; border: 1px solid lightgray; margin-right: 8px; padding: 3px;">
              <DeleteFilled />
            </el-icon>
          </el-tooltip>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页 -->
		<div class="main-body-page" style="margin-top: 10px;">
			<el-pagination 
				layout="prev, pager, next, total, jumper, sizes" 
				small 
				background 
				:total="confs.dataTotal" 
				:page-size="confs.pageLimit" 
				:page-sizes="confs.sizes" 
				v-model:current-page="confs.curPage"
				@size-change="sizeChange"
      	@current-change="currentChange"
			/>
		</div>
  </div>
</template>

<script setup>
import {
  getCurrentInstance,
  ref,
  onMounted,
  reactive
} from 'vue'

// 图片默认地址列表
const errorImgSrcList = reactive([
  require('@/assets/img/face_default.png'),
  require('@/assets/img/background_default.png'),
])

// 父组件传参
const props = defineProps(['tables', 'confs', 'filters', 'icons', 'operate'])
const emit = defineEmits(['sizeChange', 'currentChange', 'alreadyRegistered', 'mainToken', 'iconClick'])

// 初始化
onMounted(() => {
  // 将表头的样式添加到每一个表格上
  for (let index = 0; index < props.filters.length; index++) {
    if(props.filters[index].hasOwnProperty('style')) {
      tableStyleLabel.push(props.filters[index].label)
      tableStyle.push(props.filters[index].style)
    }
  }
})

// 储存单元格样式的表格
const tableStyleLabel = []
const tableStyle = []

const handleSelectionChange = (val) => {
  return emit('handleSelectionChange', val)
}

// 给单元格设置style
const cellStyle = ({ row, column, rowIndex, columnIndex }) => {
  if(tableStyleLabel.indexOf(column.label) != -1) {
    return tableStyle[tableStyleLabel.indexOf(column.label)]
  }
}

// 已注册点击事件
const ARclick = (val) => {
  for (let index = 0; index < props.filters.length; index++) {
    const element = props.filters[index];
    if(element.label === '已注册' || element.label === '主FaceToken') {
      return emit(element.action, val)
    }
  }
}

// 图标点击事件
const iconClick = (icon, val) => {
  emit('iconClick', icon, val)
}

// 页面数量变化
const sizeChange = (num) => {
  emit('sizeChange', num)
}

// 页面页码变化
const currentChange = (num) => {
  emit('currentChange', num)
}
</script>

<style lang="less">
</style>