<template>
	<div id="add_sales_dep">
		<el-dialog :title="saleTitle" v-model="pageConf.show" destroy-on-close :width="600">
			<el-form ref="formRef" class="common-dialog-form" :model="forms" :rules="rules" size="large">
				<el-form-item label="配置关键字" prop="cf_key_name">
					<el-input v-model="forms.cf_key_name" maxlength="50" :disabled="hasEdit"
						placeholder="请输入配置关键字"></el-input>
				</el-form-item>
				<el-form-item label="配置参数名称" prop="cf_param_name">
					<el-input v-model="forms.cf_param_name" maxlength="50" :disabled="hasEdit"
						placeholder="配置参数名称"></el-input>
				</el-form-item>
				<el-form-item label="配置参数值" prop="value">
					<el-input v-model="forms.value" maxlength="50" 
						placeholder="配置参数值"></el-input>
				</el-form-item>
				<el-form-item label="备注说明" prop="cf_remark">
					<el-input v-model="forms.remark" placeholder="请输入备注说明"></el-input>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" class="common-custom-forms-btn" @click="submitForm()">立即修改
					</el-button>
				</el-form-item>
			</el-form>
		</el-dialog>
	</div>
</template>

<script setup>
	import {
		getCurrentInstance,
		ref,
		reactive,
		defineExpose,
		defineEmits
	} from 'vue'

	// 访问 globalProperties
	const ctx = getCurrentInstance().appContext.config.globalProperties

	// 弹出层配置文件
	const pageConf = reactive({
		show: false
	})
	const saleTitle = ref('')
	// 显示页面
	const openPage = () => {
		saleTitle.value = '添加参数配置'
		hasEdit.value = false
		// 重置表单信息
		forms.cf_id = ''
		forms.value = ''
		forms.cf_param_name = ''
		forms.cf_key_name = ''
		forms.remark = ''
	}
	//编辑
	const hasEdit = ref(false)
	
	const editPage = async (data) => {
		saleTitle.value = '编辑参数配置'
		hasEdit.value = true
		// 重置表单信息
		forms.cf_id = data.cf_id
		forms.value = data.cf_param_val
		forms.cf_param_name = data.cf_param_name
		forms.cf_key_name = data.cf_key_name
		forms.remark = data.cf_remark
		pageConf.show = true
	}

	// 设置待抛出的方法名称
	const emit = defineEmits(['submit_suc'])

	// 表单数据
	const forms = reactive({
		remark: '',
		value: '',
		cf_param_name: '',
		cf_key_name: '',
		cf_id: ''
	})

	// 设置表单ref
	const formRef = ref()

	// 设置表单验证规则
	const rules = reactive({
		value: [{
			required: true,
			message: '请输入配置参数值',
			trigger: 'blur'
		}]
	})


	// 提交表单数据
	const submitForm = () => {
		formRef.value.validate((valid) => {
			if (valid === true) {
				ctx.$request_.post(ctx.$api_.state.Face.face_rate_set
					.edit.url, forms).then(respon => {
					if (respon.Code === ctx.$code_.state.success) {
						ctx.$notify({
							title: '温馨提示',
							message: respon.Message,
							type: 'success'
						})
						pageConf.show = false
						emit('submit_suc')
					} else {
						ctx.$notify({
							title: '温馨提示',
							message: respon.Message,
							type: 'warning'
						})
					}
				})
			}
		})
	}

	defineExpose({
		openPage,
		editPage
	})
</script>
<style lang="less">
	#add_sales_dep {
		.el-dialog {
			margin-top: 15vh !important;

			.el-dialog__body {
				padding: 0 1.1vw;
			}
		}
	}
</style>