<template>
	<div id="sales_dep">
		<div class="main-body-table">
			<!-- <CusTable ref="table_ref" title="售楼部" :datas="table.list" :cusConf="table.conf" :cusColums="table.columns"
				@chosePage="tableChosePage" @setPageSize="tablePageSize" @update="update">
			</CusTable> -->
			<SelectTable :tables="table.list" :confs="table.conf" :filters="table.columns" :icons="['Edit']" :operate="true" 
				@sizeChange="tablePageSize" @currentChange="tableChosePage" @iconClick="update">
			</SelectTable>
		</div>
		<UpdateFaceConfig ref="refUpdateFaceConfig" @submit_suc="submit_suc"></UpdateFaceConfig>
	</div>
</template>

<script setup>
	import tableColumns from '@/assets/json/face/table/face-rate-set.json'
	import CusTable from '@/components/common/CusTable.vue'
	import UpdateFaceConfig from '@/components/carema/UpdateFaceConfig.vue'
	import SelectTable from '@/components/common/SelectTable.vue'
	import {
		Picture
	} from '@element-plus/icons-vue'
	import {
		getCurrentInstance,
		ref,
		onMounted,
		reactive
	} from 'vue'
	import {
		ElMessage,
		ElMessageBox
	} from 'element-plus'

	// 访问 globalProperties
	const ctx = getCurrentInstance().appContext.config.globalProperties
	
	const refUpdateFaceConfig = ref(null)
	const update = (val, e) =>{
		let i = table.list.findIndex((value, index, array)=>{
			// 找不到的就返回-1
			return  value.cf_id === e.cf_id;
		})
		refUpdateFaceConfig.value.editPage(table.list[i])
	}
	const submit_suc = () =>{
		loadList()
	}

	// 顶部筛选
	const filter = reactive({
		keywords: '',
		bigarea: {
			options: [],
			value: '',
			is_load: false
		},
		salesDep: {
			options: [],
			value: '',
			is_load: false
		}
	});
	

	// 列表信息
	const table = reactive({
		list: [],
		columns: tableColumns.list,
		conf: {
			loadStatus: false,
			curPage: 1,
			pageLimit: 10,
			sizes: [10, 20, 30, 50, 100],
			dataTotal: 0,
			emptyText: ''
		}
	})

	// 获取table列表
	const loadList = () => {
		// 清除现有table数据
		table.list = []
		table.conf.loadStatus = true
		table.conf.emptyText = '数据查询中...'
		ctx.$request_nl_.post(ctx.$api_.state.Face.face_rate_set.list.url, {
				page: table.conf.curPage,
				limit: table.conf.pageLimit
			})
			.then((respon) => {
				if (respon.Code === ctx.$code_.state.success) {
					table.conf.dataTotal = respon.Data.total
					table.list = respon.Data.list
				} else {
					othis.$message.error({
						message: respon.Message
					});
				}
				table.conf.loadStatus = false
				table.conf.emptyText = respon.Data.list.length > 0 ? respon.Message : "暂无更多数据"
			}).catch(error => {
				table.conf.loadStatus = false
				table.conf.emptyText = "服务器连接失败，请稍后重试"
			})
	}



	// 设置table当前页码变化
	const tableChosePage = (curPage) => {
		// 存放当前页面
		table.conf.curPage = curPage
		// 重新加载table数据
		loadList()
	}

	// 设置table每页页码数
	const tablePageSize = (limit) => {
		table.conf.curPage = 1
		// 存放页码数
		table.conf.pageLimit = limit
		// 判断查询当前页  *  条数 > 总条数不进行查询
		if (table.conf.curPage === 1 || limit * table.conf.curPage <= table.conf.dataTotal) {
			// 重新加载table数据
			loadList()
		}
	}

	// 挂载
	const table_ref = ref(null)
	const add_sales_dep_ref = ref(null)

	

	onMounted(() => {
		// 初始化加载表格列表
		loadList()
	})
</script>